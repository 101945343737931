angular
    .module("messengerBotApp")
    .directive("variableHref", function () {
        return {
            /**
             * Only match attribute name.
             */
            restrict: "A",

            /**
             * Directive scope.
             */
            scope: {
                variable: "=variableHref"
            },

            /**
             * Bind href to element.
             */
            link: function (scope, element) {
                element.attr(
                    "href",
                    BASE_URL + "/projects/" + scope.variable.project_id + "/recipients/variables/" + scope.variable.id + "/edit"
                );
            }
        }
    });