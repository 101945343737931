angular
    .module("messengerBotApp")
    .directive("respondTaxonomyRenderCarouselElement", function (ApiUtils, toastr, SweetAlert, $uibModal) {
        return {
            /**
             * Only match element name.
             */
            restrict: "E",

            scope: {
                taxonomy: "=taxonomy",
                element: "=element"
            },

            /**
             * @param scope
             */
            link: function (scope) {
                /**
                 * Saving state.
                 *
                 * @type {boolean}
                 */
                scope.saving = false;

                /**
                 * Delete element.
                 *
                 * @param taxonomy
                 * @param element
                 */
                scope.deleteElement = function (taxonomy, element) {
                    SweetAlert.swal({
                        title: "Are you sure?",
                        text: "You will not be able to recover this element!",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Yes, delete it!",
                        closeOnConfirm: false
                    }, function (confirm){
                        if (confirm) {
                            ApiUtils
                                .respond
                                .taxonomy
                                .carousel
                                .element
                                .delete(PROJECT_ID, RESPOND_ID, taxonomy.id, element.id)
                                .then(function (response) {
                                    if (response.data.success) {
                                        toastr.success("Element deleted successfully.");
                                        scope.taxonomy.type.elements = scope.taxonomy.type.elements.filter(function (item) {
                                            return item.id !== element.id;
                                        });
                                    } else {
                                        toastr.error('Error occurred when deleting element.');
                                    }
                                }, function () {
                                    toastr.error('Error occurred when deleting element.');
                                });
                        }

                        SweetAlert.close();
                    });
                };

                /**
                 * Edit element.
                 *
                 * @param taxonomy
                 * @param element
                 */
                scope.editElement = function (taxonomy, element) {
                    var modalInstance = $uibModal.open({
                        templateUrl: "RespondTaxonomyCarouselElementModalController.html",
                        controller: "RespondTaxonomyCarouselElementModalController",
                        resolve: {
                            taxonomy: function () {
                                return taxonomy;
                            },
                            element: function () {
                                return element;
                            }
                        }
                    });

                    modalInstance.result.then(function (updatedElement) {
                        angular.forEach(taxonomy.type.elements, function (e, k) {
                            if (e.id == updatedElement.id) {
                                taxonomy.type.elements[k] = updatedElement;
                            }
                        });
                        toastr.success("Element updated successfully.");
                    });
                };

                /**
                 * Add new button.
                 * @param taxonomy
                 * @param element
                 * @param type
                 */
                scope.addButton = function (taxonomy, element, type) {
                    var modalInstance = $uibModal.open({
                        templateUrl: "RespondTaxonomyButton" + type + "ModalController.html",
                        controller: "RespondTaxonomyCarouselElementButton" + type + "ModalController",
                        resolve: {
                            taxonomy: function () {
                                return taxonomy;
                            },
                            element: function () {
                                return element;
                            },
                            button: function () {
                                return {}
                            }
                        }
                    });

                    modalInstance.result.then(function (button) {
                        element.buttons.push(button);
                        toastr.success("Button added successfully.");
                    });
                };
            },

            /**
             * Template url.
             */
            templateUrl: "respond.taxonomy.render.carousel.element.html"
        }
    });
