angular
    .module("messengerBotApp")
    .directive("respondTaxonomyRenderQuickRepliesQuickReply", function (ApiUtils, toastr, SweetAlert, $uibModal) {
        return {
            /**
             * Only match element name.
             */
            restrict: "E",

            scope: {
                taxonomy: "=taxonomy",
                quickReply: "=quickReply"
            },

            /**
             * @param scope
             */
            link: function (scope) {
                /**
                 * Button copy.
                 */
                var quickReplyCopy = angular.copy(scope.quickReply);

                /**
                 * Saving state.
                 *
                 * @type {boolean}
                 */
                scope.saving = false;

                /**
                 * Delete button.
                 *
                 * @param taxonomy
                 * @param quickReply
                 */
                scope.deleteQuickReply = function (taxonomy, quickReply) {
                    SweetAlert.swal({
                        title: "Are you sure?",
                        text: "You will not be able to recover this quick reply!",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Yes, delete it!",
                        closeOnConfirm: false
                    }, function (confirm){
                        if (confirm) {
                            ApiUtils
                                .respond
                                .taxonomy
                                .quickReply
                                .quickReply
                                .delete(PROJECT_ID, RESPOND_ID, taxonomy.id, quickReply.id)
                                .then(function (response) {
                                    if (response.data.success) {
                                        toastr.success("Quick reply deleted successfully.");
                                        scope.taxonomy.type.options = scope.taxonomy.type.options.filter(function (item) {
                                            return item.id !== quickReply.id;
                                        });
                                    } else {
                                        toastr.error('Error occurred when deleting quick reply.');
                                    }
                                }, function () {
                                    toastr.error('Error occurred when deleting quick reply.');
                                });
                        }

                        SweetAlert.close();
                    });
                };

                /**
                 * Edit button.
                 *
                 * @param taxonomy
                 * @param quickReply
                 */
                scope.editQuickReply = function (taxonomy, quickReply) {
                    var modalInstance = $uibModal.open({
                        templateUrl: "RespondTaxonomyQuickReplyTextModalController.html",
                        controller: "RespondTaxonomyQuickReplyTextModalController",
                        resolve: {
                            taxonomy: function () {
                                return taxonomy;
                            },
                            option: function () {
                                return quickReply;
                            }
                        }
                    });

                    modalInstance.result.then(function (updatedQuickReply) {
                        angular.forEach(taxonomy.type.options, function (q, k) {
                            if (q.id == updatedQuickReply.id) {
                                taxonomy.type.options[k] = updatedQuickReply;
                            }
                        });
                        toastr.success("Quick reply updated successfully.");
                    });
                };
            },

            /**
             * Return template.
             *
             * @returns {string}
             */
            template: function () {
                return "<div class=\"btn-group btn-group-xs\"><button class=\"btn btn-primary\" type=\"button\">{{ quickReply.type.title }}</button><button class=\"btn btn-danger\" tooltip-trigger=\"mouseenter\" uib-tooltip=\"Delete quick reply\" type=\"button\" ng-click=\"deleteQuickReply(taxonomy, quickReply)\"><i class=\"fa fa-trash\"></i></button><button class=\"btn btn-warning\" type=\"button\" uib-tooltip=\"Edit quick reply\" tooltip-trigger=\"mouseenter\" ng-click=\"editQuickReply(taxonomy, quickReply)\"><i class=\"fa fa-pencil\"></i></button></div>";
            }
        }
    });
