angular
    .module("messengerBotApp")
    .controller("RespondTaxonomyQuickReplyController", function ($scope, $uibModal, toastr) {
        /**
         * Add new button.
         * @param taxonomy
         * @param type
         */
        $scope.addQuickReply = function (taxonomy, type) {
            var modalInstance = $uibModal.open({
                templateUrl: "RespondTaxonomyQuickReplyTextModalController.html",
                controller: "RespondTaxonomyQuickReplyTextModalController",
                resolve: {
                    taxonomy: function () {
                        return $scope.taxonomy;
                    },
                    option: function () {
                        return {}
                    }
                }
            });

            modalInstance.result.then(function (option) {
                $scope.taxonomy.type.options.push(option);
                toastr.success("Quick reply added successfully.");
            });
        }
    });