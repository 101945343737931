angular
    .module("messengerBotApp")
    .controller("ProjectSettingsThreadController", function ($scope, ApiUtils, toastr, $http) {
        /**
         * Set initial updating state.
         *
         * @type {boolean}
         */
        $scope.updatingGreetingText = false;

        /**
         * Initial errors.
         *
         * @type {{}}
         */
        $scope.greetingTextErrors = {};

        /**
         * Process save.
         */
        $scope.updateGreetingText = function () {
            $scope.updatingGreetingText = true;

            ApiUtils
                .project
                .threadSettings
                .update
                .greetingText(PROJECT_ID, $scope.project.thread_settings.greeting_text)
                .then(function () {
                    toastr.success("Greeting text updated successfully.");
                }, function (response) {
                    $scope.greetingTextErrors = response.data;
                    toastr.error("Failed to update greeting text.");
                })
                .finally(function () {
                    $scope.updatingGreetingText = false;
                });
        };

        /**
         * If option has changed reset errors.
         */
        $scope.$watch("project.thread_settings.greeting_text", function () {
            $scope.greetingTextErrors.greeting_text = [];
        });

        /**
         * Determine if responds are loading.
         *
         * @type {boolean}
         */
        $scope.respondsLoading = true;

        /**
         * Load responds.
         */
        $http.get(BASE_URL + "/api/project/" + PROJECT_ID + "/respond").then(function (response) {
            $scope.responds = response.data;
            $scope.respondsLoading = false;
        });

        /**
         * Set initial updating state.
         *
         * @type {boolean}
         */
        $scope.updatingGetStartedRespond = false;

        /**
         * Initial errors.
         *
         * @type {{}}
         */
        $scope.getStartedRespondErrors = {};

        /**
         * Process save.
         */
        $scope.updateGetStartedRespond = function () {
            $scope.updatingGetStartedRespond = true;

            ApiUtils
                .project
                .threadSettings
                .update
                .getStarted(PROJECT_ID, $scope.project.thread_settings.get_started_respond ? $scope.project.thread_settings.get_started_respond.id : null)
                .then(function () {
                    toastr.success("Get started respond updated successfully.");
                }, function (response) {
                    $scope.getStartedRespondErrors = response.data;
                    toastr.error("Failed to update get started respond.");
                })
                .finally(function () {
                    $scope.updatingGetStartedRespond = false;
                });
        };

        /**
         * If option has changed reset errors.
         */
        $scope.$watch("project.thread_settings.get_started_respond", function () {
            $scope.greetingTextErrors.respond = [];
        });

        /**
         * Determine if responds are loading.
         *
         * @type {boolean}
         */
        $scope.menuRespondsLoading = true;

        /**
         * Load responds.
         */
        $http
            .get(BASE_URL + "/api/project/" + PROJECT_ID + "/respond", { params: { label: "persistent_menu" } })
            .then(function (response) {
                $scope.menuResponds = response.data;
                $scope.menuRespondsLoading = false;
            });

        /**
         * Set initial updating state.
         *
         * @type {boolean}
         */
        $scope.updatingMenuRespond = false;

        /**
         * Initial errors.
         *
         * @type {{}}
         */
        $scope.menuRespondErrors = {};

        /**
         * Process save.
         */
        $scope.updateMenuRespond = function () {
            $scope.updatingMenuRespond = true;

            ApiUtils
                .project
                .threadSettings
                .update
                .persistentMenu(PROJECT_ID, $scope.project.thread_settings.persistent_menu_respond ? $scope.project.thread_settings.persistent_menu_respond.id : null)
                .then(function () {
                    toastr.success("Persistent menu updated successfully.");
                }, function (response) {
                    $scope.getStartedRespondErrors = response.data;
                    toastr.error("Failed to update persistent menu.");
                })
                .finally(function () {
                    $scope.updatingMenuRespond = false;
                });
        };

        /**
         * If option has changed reset errors.
         */
        $scope.$watch("project.thread_settings.persistent_menu_respond", function () {
            $scope.menuRespondErrors.respond = [];
        });
    });