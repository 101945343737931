angular
    .module("messengerBotApp")
    .controller("RespondTaxonomyFormBtnPostbackModalController", function ($scope, ApiUtils, $uibModalInstance, $http, taxonomy) {
        /**
         * Set initial validation state.
         *
         * @type {boolean}
         */
        $scope.saving = false;

        /**
         * Initial errors.
         *
         * @type {{}}
         */
        $scope.errors = {};

        /**
         * @type {*}
         */
        $scope.taxonomy = angular.copy(taxonomy);

        /**
         * @type {boolean}
         */
        $scope.respondsLoading = true;

        /**
         * Load responds.
         */
        $http.get(BASE_URL + "/api/project/" + PROJECT_ID + "/respond").then(function (response) {
            $scope.responds = response.data;
            $scope.respondsLoading = false;
        });

        /**
         * Save matcher.
         */
        $scope.save = function () {
            $scope.saving = true;

            if (!$scope.taxonomy.id) {
                ApiUtils
                    .respond
                    .taxonomy
                    .btn
                    .postback
                    .store(PROJECT_ID, RESPOND_ID, $scope.taxonomy.type)
                    .then(function (response) {
                        $uibModalInstance.close(response.data);
                    }, function (response) {
                        $scope.errors = response.data;
                    })
                    .finally(function () {
                        $scope.saving = false;
                    });
            } else {
                ApiUtils
                    .respond
                    .taxonomy
                    .btn
                    .postback
                    .update(PROJECT_ID, RESPOND_ID, taxonomy.id, $scope.taxonomy.type)
                    .then(function (response) {
                        $uibModalInstance.close(response.data);
                    }, function (response) {
                        $scope.errors = response.data;
                    })
                    .finally(function () {
                        $scope.saving = false;
                    });
            }
        };

        /**
         * Dismiss modal.
         */
        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        /**
         * If option has changed reset errors.
         */
        $scope.$watch("taxonomy.type.title", function () {
            $scope.errors.title = [];
        });

        /**
         * If option has changed reset errors.
         */
        $scope.$watch("taxonomy.type.responds", function () {
            $scope.errors.responds = [];
        });
    });