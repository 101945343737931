angular
    .module("messengerBotApp")
    .controller("RespondTaxonomyFormVideoModalController", function ($scope, ApiUtils, $uibModalInstance, taxonomy) {
        /**
         * Set initial validation state.
         *
         * @type {boolean}
         */
        $scope.saving = false;

        /**
         * Initial errors.
         *
         * @type {{}}
         */
        $scope.errors = {};

        if (taxonomy.id) {
            $scope.taxonomy = angular.copy(taxonomy);
        } else {
            $scope.taxonomy = { type: {} };
        }

        /**
         * Save matcher.
         */
        $scope.save = function () {
            $scope.saving = true;

            if (!$scope.taxonomy.id) {
                ApiUtils
                    .respond
                    .taxonomy
                    .video
                    .url
                    .store(PROJECT_ID, RESPOND_ID, $scope.taxonomy.type.type)
                    .then(function (response) {
                        $uibModalInstance.close(response.data);
                    }, function (response) {
                        $scope.errors = response.data;
                    })
                    .finally(function () {
                        $scope.saving = false;
                    });
            } else {
                ApiUtils
                    .respond
                    .taxonomy
                    .video
                    .url
                    .update(PROJECT_ID, RESPOND_ID, taxonomy.id, $scope.taxonomy.type.type)
                    .then(function (response) {
                        $uibModalInstance.close(response.data);
                    }, function (response) {
                        $scope.errors = response.data;
                    })
                    .finally(function () {
                        $scope.saving = false;
                    });
            }
        };

        /**
         * Dismiss modal.
         */
        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        /**
         * If option has changed reset errors.
         */
        $scope.$watch("taxonomy.type.type.url", function () {
            $scope.errors.url = [];
        });
    });