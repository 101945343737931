angular
    .module("messengerBotApp")
    .directive("respondTaxonomyButtonTypeIcon", function () {
        var icons = {
            "respond.type.button.url": {
                icon: "fa-link",
                description: "Send URL"
            },
            "respond.type.button.postback": {
                icon: "fa-reply",
                description: "Send action"
            },
            "respond.type.button.phone": {
                icon: "fa-phone",
                description: "Send phone number"
            }
        };

        return {
            /**
             * Only match element name.
             */
            restrict: "E",

            scope: {
                button: "=button"
            },

            /**
             * @param scope
             */
            link: function (scope) {
                /**
                 * Return icon.
                 *
                 * @param button
                 * @returns {*}
                 */
                scope.getIcon = function (button) {
                    return icons[button.type_type].icon;
                };

                /**
                 * Return description.
                 *
                 * @param button
                 * @returns {*}
                 */
                scope.getDescription = function (button) {
                    return icons[button.type_type].description;
                };
            },

            /**
             * Return template.
             *
             * @returns {string}
             */
            template: function () {
                return "<span uib-tooltip=\"{{ getDescription(button) }}\" tooltip-trigger=\"mouseenter\"><i class=\"fa {{ getIcon(button) }}\"</span>"
            }
        }
    });
