angular
    .module("messengerBotApp")
    .directive("respondTaxonomyView", function ($compile) {
        return {
            /**
             * Only match element name.
             */
            restrict: "E",

            scope: {
                taxonomy: "=taxonomy"
            },

            /**
             * Return template url.
             *
             * @param scope
             * @param element
             * @param attrs
             */
            link: function (scope, element, attrs) {
                element.html("<ng-include src=\"'" + scope.taxonomy.type_type + ".view.html'\"></ng-include>").show();
                $compile(element.contents())(scope);
            }
        }
    });
