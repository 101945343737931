angular
    .module("messengerBotApp")
    .controller("RespondTaxonomyButtonController", function ($scope, $uibModal, toastr) {
        /**
         * Add new button.
         * @param taxonomy
         * @param type
         */
        $scope.addButton = function (taxonomy, type) {
            var modalInstance = $uibModal.open({
                templateUrl: "RespondTaxonomyButton" + type + "ModalController.html",
                controller: "RespondTaxonomyButton" + type + "ModalController",
                resolve: {
                    taxonomy: function () {
                        return $scope.taxonomy;
                    },
                    button: function () {
                        return {}
                    }
                }
            });

            modalInstance.result.then(function (button) {
                $scope.taxonomy.type.buttons.push(button);
                toastr.success("Button added successfully.");
            });
        }
    });