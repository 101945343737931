angular
    .module("messengerBotApp")
    .controller("ProjectSettingsGeneralController", function ($scope, ApiUtils, toastr) {
        /**
         * Set initial saving state.
         *
         * @type {boolean}
         */
        $scope.saving = false;

        /**
         * Initial errors.
         *
         * @type {{}}
         */
        $scope.errors = {};

        /**
         * Process save.
         */
        $scope.save = function () {
            $scope.saving = true;

            ApiUtils
                .project
                .update(PROJECT_ID, $scope.project)
                .then(function () {
                    toastr.success("Project details updated successfully.");
                }, function (response) {
                    $scope.errors = response.data;
                    toastr.error("Failed to update project details.");
                })
                .finally(function () {
                    $scope.saving = false;
                });
        };

        /**
         * If option has changed reset errors.
         */
        $scope.$watch("project.title", function () {
            $scope.errors.title = [];
        });

        /**
         * If option has changed reset errors.
         */
        $scope.$watch("project.timezone", function () {
            $scope.errors.timezone = [];
        });
    });