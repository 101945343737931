angular
    .module("messengerBotApp")
    .directive("respondTaxonomyTypeIndicator", function () {
        var labels = {
            "respond.type.audio": "audio",
            "respond.type.button": "buttons",
            "respond.type.callback": "callback",
            "respond.type.carousel": "carousel",
            "respond.type.chat_toggle": "chat toggle",
            "respond.type.file": "file",
            "respond.type.image": "image",
            "respond.type.ping": "ping",
            "respond.type.quick_reply": "quick replies",
            "respond.type.rss": "rss",
            "respond.type.save_input": "save input",
            "respond.type.subscribe": "subscribe",
            "respond.type.text": "text",
            "respond.type.video": "video"
        };

        return {
            /**
             * Only match element name.
             */
            restrict: "E",

            scope: {
                taxonomy: "=taxonomy"
            },

            /**
             * @param scope
             */
            link: function (scope) {
                /**
                 * Return label.
                 *
                 * @param config
                 * @returns {*}
                 */
                scope.getLabel = function (taxonomy) {
                    return labels[taxonomy.type_type];
                };
            },

            /**
             * Return template.
             *
             * @returns {string}
             */
            template: function () {
                return "<span class=\"label label-info\" uib-tooltip=\"{{ getLabel(taxonomy) }}\" tooltip-trigger=\"mouseenter\">{{ getLabel(taxonomy) }}</span>"
            }
        }
    });
