angular
    .module("messengerBotApp")
    .directive("respondLabelIndicator", function () {
        var icons = {
            "persistent_menu": {
                icon: "fa-bars",
                description: "Persistent menu"
            },
            "general": {
                icon: "fa-th",
                description: "General"
            }
        };

        return {
            /**
             * Only match element name.
             */
            restrict: "E",

            scope: {
                respond: "=respond"
            },

            /**
             * @param scope
             */
            link: function (scope) {
                /**
                 * @param respond
                 * @returns {*}
                 */
                var label = function (respond) {
                    var label = respond.label;

                    if (label == null) {
                        label = "general";
                    }

                    return label;
                };

                /**
                 * Return icon.
                 *
                 * @param respond
                 * @returns {*}
                 */
                scope.getIcon = function (respond) {
                    return icons[label(respond)].icon;
                };

                /**
                 * Return description.
                 *
                 * @param respond
                 * @returns {*}
                 */
                scope.getDescription = function (respond) {
                    return icons[label(respond)].description;
                };
            },

            /**
             * Return template.
             *
             * @returns {string}
             */
            template: function () {
                return "<small uib-tooltip=\"{{ getDescription(respond) }}\" tooltip-trigger=\"mouseenter\"><i class=\"fa {{ getIcon(respond) }}\"></i></small>"
            }
        }
    });
