angular
    .module("messengerBotApp")
    .service("ApiUtils", function ($http) {
        return {
            /**
             * Project helpers.
             */
            project: {
                /**
                 * Fetch analytics data.
                 *
                 * @param projectId
                 * @param fields
                 * @param start
                 * @param end
                 * @returns HttpPromise
                 */
                analytics: function (projectId, fields, start, end) {
                    return $http.get(BASE_URL + "/api/project/" + projectId + "/analytics", {
                        params: {
                            "fields[]": fields,
                            start: start,
                            end: end
                        }
                    });
                },

                /**
                 * Update project.
                 *
                 * @param projectId
                 * @param project
                 * @returns HttpPromise
                 */
                update: function (projectId, project) {
                    return $http.put(BASE_URL + "/api/project/" + projectId, project);
                },

                /**
                 * Delete project.
                 *
                 * @param projectId
                 * @param title
                 * @returns HttpPromise
                 */
                delete: function (projectId, title) {
                    return $http.delete(BASE_URL + "/api/project/" + projectId, { params: { title: title } });
                },

                /**
                 * Thread settings helpers.
                 */
                threadSettings: {
                    /**
                     * Update thread settings.
                     */
                    update: {
                        /**
                         * Greeting text.
                         *
                         * @param projectId
                         * @param text
                         * @returns HttpPromise
                         */
                        greetingText: function (projectId, text) {
                            return $http.put(BASE_URL + "/api/project/" + projectId + "/setting/thread/greeting", { text: text });
                        },

                        /**
                         * Get started respond.
                         *
                         * @param projectId
                         * @param respondId
                         * @returns HttpPromise
                         */
                        getStarted: function (projectId, respondId) {
                            return $http.put(BASE_URL + "/api/project/" + projectId + "/setting/thread/getStarted", { respond: respondId });
                        },

                        /**
                         * Persistent menu respond.
                         *
                         * @param projectId
                         * @param respondId
                         * @returns HttpPromise
                         */
                        persistentMenu: function (projectId, respondId) {
                            return $http.put(BASE_URL + "/api/project/" + projectId + "/setting/thread/persistentMenu", { respond: respondId });
                        }
                    }
                }
            },

            /**
             * Recipient helpers.
             */
            recipient: {
                /**
                 * Chat helpers.
                 */
                chat: {
                    /**
                     * Disable recipient chat.
                     *
                     * @param projectId
                     * @param recipientId
                     * @returns HttpPromise
                     */
                    disable: function (projectId, recipientId) {
                        return $http.post(BASE_URL + "/api/project/" + projectId + "/recipient/" + recipientId + "/chat/disable");
                    },

                    /**
                     * Enable recipient chat.
                     *
                     * @param projectId
                     * @param recipientId
                     * @returns HttpPromise
                     */
                    enable: function (projectId, recipientId) {
                        return $http.post(BASE_URL + "/api/project/" + projectId + "/recipient/" + recipientId + "/chat/enable");
                    }
                },

                /**
                 * Refresh data from API.
                 *
                 * @param projectId
                 * @param recipientId
                 * @returns HttpPromise
                 */
                refresh: function (projectId, recipientId) {
                    return $http.post(BASE_URL + "/api/project/" + projectId + "/recipient/" + recipientId + "/refresh");
                },

                /**
                 * Channel helpers.
                 */
                channel: {
                    /**
                     * Load all recipient channels.
                     *
                     * @param projectId
                     * @param recipientId
                     * @returns HttpPromise
                     */
                    index: function (projectId, recipientId) {
                        return $http.get(BASE_URL + "/api/project/" + projectId + "/recipient/" + recipientId + "/channel", {
                            params: {
                                all: true
                            }
                        });
                    },

                    /**
                     * Remove recipient from channel.
                     *
                     * @param projectId
                     * @param recipientId
                     * @param channelId
                     * @returns HttpPromise
                     */
                    delete: function (projectId, recipientId, channelId) {
                        return $http.delete(BASE_URL + "/api/project/" + projectId + "/recipient/" + recipientId + "/channel/" + channelId);
                    },

                    /**
                     * Attach new channels.
                     *
                     * @param projectId
                     * @param recipientId
                     * @param channels
                     * @returns HttpPromise
                     */
                    store: function (projectId, recipientId, channels) {
                        return $http.post(BASE_URL + "/api/project/" + projectId + "/recipient/" + recipientId + "/channel", { channels: channels });
                    }
                }
            },

            /**
             * Subscription helpers.
             */
            subscription: {
                /**
                 * Channel helpers.
                 */
                channel: {
                    /**
                     * Store new channel.
                     *
                     * @param projectId
                     * @param channel
                     * @returns HttpPromise
                     */
                    store: function (projectId, channel) {
                        return $http.post(BASE_URL + "/api/project/" + projectId + "/subscription/channel", channel);
                    },

                    /**
                     * Update existing channel.
                     *
                     * @param projectId
                     * @param channelId
                     * @param channel
                     * @returns HttpPromise
                     */
                    update: function (projectId, channelId, channel) {
                        return $http.put(BASE_URL + "/api/project/" + projectId + "/subscription/channel/" + channelId, channel);
                    },

                    /**
                     * Delete channel.
                     *
                     * @param projectId
                     * @param channelId
                     * @returns HttpPromise
                     */
                    delete: function (projectId, channelId) {
                        return $http.delete(BASE_URL + "/api/project/" + projectId + "/subscription/channel/" + channelId);
                    },

                    /**
                     * Fetch analytics data.
                     *
                     * @param projectId
                     * @param channelId
                     * @param fields
                     * @param start
                     * @param end
                     * @returns HttpPromise
                     */
                    analytics: function (projectId, channelId, fields, start, end) {
                        return $http.get(BASE_URL + "/api/project/" + projectId + "/subscription/channel/" + channelId + "/analytics", {
                            params: {
                                "fields[]": fields,
                                start: start,
                                end: end
                            }
                        });
                    },

                    /**
                     * Recipient helpers.
                     */
                    recipient: {
                        /**
                         * Store new recipients.
                         *
                         * @param projectId
                         * @param channelId
                         * @param recipients
                         * @returns HttpPromise
                         */
                        store: function (projectId, channelId, recipients) {
                            return $http.post(BASE_URL + "/api/project/" + projectId + "/subscription/channel/" + channelId + "/recipient", {
                                recipients: recipients
                            });
                        },

                        /**
                         * Remove recipient from a channel.
                         *
                         * @param projectId
                         * @param channelId
                         * @param recipientId
                         * @returns HttpPromises
                         */
                        delete: function (projectId, channelId, recipientId) {
                            return $http.delete(BASE_URL + "/api/project/" + projectId + "/subscription/channel/" + channelId + "/recipient/" + recipientId);
                        }
                    },

                    /**
                     * Broadcast helpers.
                     */
                    broadcast: {
                        /**
                         * Retrieve single broadcast.
                         *
                         * @param projectId
                         * @param channelId
                         * @param broadcastId
                         * @returns HttpPromise
                         */
                        show: function (projectId, channelId, broadcastId) {
                            return $http.get(BASE_URL + "/api/project/" + projectId + "/subscription/channel/" + channelId + "/broadcast/" + broadcastId);
                        },

                        /**
                         * Store new broadcast.
                         *
                         * @param projectId
                         * @param channelId
                         * @param broadcast
                         * @returns HttpPromise
                         */
                        store: function (projectId, channelId, broadcast) {
                            return $http.post(BASE_URL + "/api/project/" + projectId + "/subscription/channel/" + channelId + "/broadcast", broadcast);
                        },

                        /**
                         * Remove recipient from a channel.
                         *
                         * @param projectId
                         * @param channelId
                         * @param broadcastId
                         * @returns HttpPromises
                         */
                        delete: function (projectId, channelId, broadcastId) {
                            return $http.delete(BASE_URL + "/api/project/" + projectId + "/subscription/channel/" + channelId + "/broadcast/" + broadcastId);
                        }
                    }
                }
            },

            /**
             * Message helpers.
             */
            message: {
                /**
                 * Retrieve single message.
                 *
                 * @param projectId
                 * @param messageId
                 * @returns HttpPromise
                 */
                show: function (projectId, messageId) {
                    return $http.get(BASE_URL + "/api/project/" + projectId + "/message/" + messageId);
                },

                /**
                 * Remove message.
                 *
                 * @param projectId
                 * @param messageId
                 * @returns HttpPromises
                 */
                delete: function (projectId, messageId) {
                    return $http.delete(BASE_URL + "/api/project/" + projectId + "/message/" + messageId);
                },

                /**
                 * Store new message.
                 *
                 * @param projectId
                 * @param message
                 * @returns HttpPromise
                 */
                store: function (projectId, message) {
                    return $http.post(BASE_URL + "/api/project/" + projectId + "/message", message);
                }
            },

            /**
             * Respond helper.
             */
            respond: {
                /**
                 * Update existing respond.
                 *
                 * @param projectId
                 * @param respondId
                 * @param respond
                 * @returns HttpPromise
                 */
                update: function (projectId, respondId, respond) {
                    return $http.put(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId, respond);
                },

                /**
                 * Taxonomy helpers.
                 */
                taxonomy: {
                    /**
                     * Sort.
                     *
                     * @param projectId
                     * @param respondId
                     * @param positions
                     * @returns HttpPromise
                     */
                    sort: function (projectId, respondId, positions) {
                        return $http.post(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/sort", { sort: positions });
                    },

                    /**
                     * Delete.
                     *
                     * @param projectId
                     * @param respondId
                     * @param taxonomyId
                     * @returns HttpPromise
                     */
                    delete: function (projectId, respondId, taxonomyId) {
                        return $http.delete(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/" + taxonomyId);
                    },

                    /**
                     * Audio helpers.
                     */
                    audio: {
                        /**
                         * URL option helpers.
                         */
                        url: {
                            /**
                             * Store new audio.
                             *
                             * @param projectId
                             * @param respondId
                             * @param audio
                             * @returns HttpPromise
                             */
                            store: function (projectId, respondId, audio) {
                                return $http.post(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/audio/url", audio);
                            },

                            /**
                             * Update existing audio.
                             *
                             * @param projectId
                             * @param respondId
                             * @param taxonomyId
                             * @param audio
                             * @returns HttpPromise
                             */
                            update: function (projectId, respondId, taxonomyId, audio) {
                                return $http.put(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/" + taxonomyId + "/audio/url", audio);
                            }
                        }
                    },

                    /**
                     * Callback helpers.
                     */
                    callback: {
                        /**
                         * Store new callback.
                         *
                         * @param projectId
                         * @param respondId
                         * @param callback
                         * @returns HttpPromise
                         */
                        store: function (projectId, respondId, callback) {
                            return $http.post(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/callback", callback);
                        },

                        /**
                         * Update existing callback.
                         *
                         * @param projectId
                         * @param respondId
                         * @param taxonomyId
                         * @param callback
                         * @returns HttpPromise
                         */
                        update: function (projectId, respondId, taxonomyId, callback) {
                            return $http.put(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/" + taxonomyId + "/callback", callback);
                        }
                    },

                    /**
                     * Chat toggle helpers.
                     */
                    chatToggle: {
                        /**
                         * Store new chatToggle.
                         *
                         * @param projectId
                         * @param respondId
                         * @param chatToggle
                         * @returns HttpPromise
                         */
                        store: function (projectId, respondId, chatToggle) {
                            return $http.post(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/chatToggle", chatToggle);
                        },

                        /**
                         * Update existing chatToggle.
                         *
                         * @param projectId
                         * @param respondId
                         * @param taxonomyId
                         * @param chatToggle
                         * @returns HttpPromise
                         */
                        update: function (projectId, respondId, taxonomyId, chatToggle) {
                            return $http.put(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/" + taxonomyId + "/chatToggle", chatToggle);
                        }
                    },

                    /**
                     * File helpers.
                     */
                    file: {
                        /**
                         * URL option helpers.
                         */
                        url: {
                            /**
                             * Store new file.
                             *
                             * @param projectId
                             * @param respondId
                             * @param file
                             * @returns HttpPromise
                             */
                            store: function (projectId, respondId, file) {
                                return $http.post(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/file/url", file);
                            },

                            /**
                             * Update existing file.
                             *
                             * @param projectId
                             * @param respondId
                             * @param taxonomyId
                             * @param file
                             * @returns HttpPromise
                             */
                            update: function (projectId, respondId, taxonomyId, file) {
                                return $http.put(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/" + taxonomyId + "/file/url", file);
                            }
                        }
                    },

                    /**
                     * Image helpers.
                     */
                    image: {
                        /**
                         * URL option helpers.
                         */
                        url: {
                            /**
                             * Store new image.
                             *
                             * @param projectId
                             * @param respondId
                             * @param image
                             * @returns HttpPromise
                             */
                            store: function (projectId, respondId, image) {
                                return $http.post(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/image/url", image);
                            },

                            /**
                             * Update existing image.
                             *
                             * @param projectId
                             * @param respondId
                             * @param taxonomyId
                             * @param image
                             * @returns HttpPromise
                             */
                            update: function (projectId, respondId, taxonomyId, image) {
                                return $http.put(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/" + taxonomyId + "/image/url", image);
                            }
                        }
                    },

                    /**
                     * Ping helpers.
                     */
                    ping: {
                        /**
                         * Store new ping.
                         *
                         * @param projectId
                         * @param respondId
                         * @param ping
                         * @returns HttpPromise
                         */
                        store: function (projectId, respondId, ping) {
                            return $http.post(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/ping", ping);
                        },

                        /**
                         * Update existing ping.
                         *
                         * @param projectId
                         * @param respondId
                         * @param taxonomyId
                         * @param ping
                         * @returns HttpPromise
                         */
                        update: function (projectId, respondId, taxonomyId, ping) {
                            return $http.put(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/" + taxonomyId + "/ping", ping);
                        }
                    },

                    /**
                     * Quick reply helpers.
                     */
                    quickReply: {
                        /**
                         * Helpers.
                         */
                        quickReply: {
                            /**
                             * Text button helpers.
                             */
                            text: {
                                /**
                                 * Store.
                                 *
                                 * @param projectId
                                 * @param respondId
                                 * @param taxonomyId
                                 * @param text
                                 * @returns HttpPromise
                                 */
                                store: function (projectId, respondId, taxonomyId, text) {
                                    return $http.post(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/" + taxonomyId + "/quickReply/option/text", text);
                                },

                                /**
                                 * Update.
                                 *
                                 * @param projectId
                                 * @param respondId
                                 * @param taxonomyId
                                 * @param optionId
                                 * @param text
                                 * @returns HttpPromise
                                 */
                                update: function (projectId, respondId, taxonomyId, optionId, text) {
                                    return $http.put(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/" + taxonomyId + "/quickReply/option/text/" + optionId, text);
                                }
                            },

                            /**
                             * Delete option.
                             *
                             * @param projectId
                             * @param respondId
                             * @param taxonomyId
                             * @param quickReplyId
                             * @returns HttpPromise
                             */
                            delete: function (projectId, respondId, taxonomyId, quickReplyId) {
                                return $http.delete(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/" + taxonomyId + "/quickReply/option/" + quickReplyId);
                            }
                        },

                        /**
                         * Store new quickReply.
                         *
                         * @param projectId
                         * @param respondId
                         * @param quickReply
                         * @returns HttpPromise
                         */
                        store: function (projectId, respondId, quickReply) {
                            return $http.post(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/quickReply", quickReply);
                        },

                        /**
                         * Update existing quickReply.
                         *
                         * @param projectId
                         * @param respondId
                         * @param taxonomyId
                         * @param quickReply
                         * @returns HttpPromise
                         */
                        update: function (projectId, respondId, taxonomyId, quickReply) {
                            return $http.put(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/" + taxonomyId + "/quickReply", quickReply);
                        }
                    },

                    /**
                     * Rss helpers.
                     */
                    rss: {
                        /**
                         * Store new rss.
                         *
                         * @param projectId
                         * @param respondId
                         * @param rss
                         * @returns HttpPromise
                         */
                        store: function (projectId, respondId, rss) {
                            return $http.post(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/rss", rss);
                        },

                        /**
                         * Update existing rss.
                         *
                         * @param projectId
                         * @param respondId
                         * @param taxonomyId
                         * @param rss
                         * @returns HttpPromise
                         */
                        update: function (projectId, respondId, taxonomyId, rss) {
                            return $http.put(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/" + taxonomyId + "/rss", rss);
                        }
                    },

                    /**
                     * Save input helpers.
                     */
                    saveInput: {
                        /**
                         * Store new saveInput.
                         *
                         * @param projectId
                         * @param respondId
                         * @param saveInput
                         * @returns HttpPromise
                         */
                        store: function (projectId, respondId, saveInput) {
                            return $http.post(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/saveInput", saveInput);
                        },

                        /**
                         * Update existing saveInput.
                         *
                         * @param projectId
                         * @param respondId
                         * @param taxonomyId
                         * @param saveInput
                         * @returns HttpPromise
                         */
                        update: function (projectId, respondId, taxonomyId, saveInput) {
                            return $http.put(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/" + taxonomyId + "/saveInput", saveInput);
                        }
                    },

                    /**
                     * Subscribe helpers.
                     */
                    subscribe: {
                        /**
                         * Store new subscribe.
                         *
                         * @param projectId
                         * @param respondId
                         * @param subscribe
                         * @returns HttpPromise
                         */
                        store: function (projectId, respondId, subscribe) {
                            return $http.post(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/subscribe", subscribe);
                        },

                        /**
                         * Update existing subscribe.
                         *
                         * @param projectId
                         * @param respondId
                         * @param taxonomyId
                         * @param subscribe
                         * @returns HttpPromise
                         */
                        update: function (projectId, respondId, taxonomyId, subscribe) {
                            return $http.put(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/" + taxonomyId + "/subscribe", subscribe);
                        }
                    },

                    /**
                     * Text helpers.
                     */
                    text: {
                        /**
                         * Store new text.
                         *
                         * @param projectId
                         * @param respondId
                         * @param text
                         * @returns HttpPromise
                         */
                        store: function (projectId, respondId, text) {
                            return $http.post(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/text", text);
                        },

                        /**
                         * Update existing text.
                         *
                         * @param projectId
                         * @param respondId
                         * @param taxonomyId
                         * @param text
                         * @returns HttpPromise
                         */
                        update: function (projectId, respondId, taxonomyId, text) {
                            return $http.put(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/" + taxonomyId + "/text", text);
                        }
                    },

                    /**
                     * Video helpers.
                     */
                    video: {
                        /**
                         * URL option helpers.
                         */
                        url: {
                            /**
                             * Store new video.
                             *
                             * @param projectId
                             * @param respondId
                             * @param video
                             * @returns HttpPromise
                             */
                            store: function (projectId, respondId, video) {
                                return $http.post(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/video/url", video);
                            },

                            /**
                             * Update existing video.
                             *
                             * @param projectId
                             * @param respondId
                             * @param taxonomyId
                             * @param video
                             * @returns HttpPromise
                             */
                            update: function (projectId, respondId, taxonomyId, video) {
                                return $http.put(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/" + taxonomyId + "/video/url", video);
                            }
                        }
                    },

                    /**
                     * Carousel helpers.
                     */
                    carousel: {
                        /**
                         * Element helpers.
                         */
                        element: {
                            /**
                             * Sort.
                             *
                             * @param projectId
                             * @param respondId
                             * @param taxonomyId
                             * @param positions
                             * @returns HttpPromise
                             */
                            sort: function (projectId, respondId, taxonomyId, positions) {
                                return $http.post(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/" + taxonomyId + "/carousel/element/sort", { sort: positions });
                            },

                            /**
                             * Store element.
                             *
                             * @param projectId
                             * @param respondId
                             * @param taxonomyId
                             * @param element
                             * @returns HttpPromise
                             */
                            store: function (projectId, respondId, taxonomyId, element) {
                                return $http.post(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/" + taxonomyId + "/carousel/element", element);
                            },

                            /**
                             * Update element.
                             *
                             * @param projectId
                             * @param respondId
                             * @param taxonomyId
                             * @param elementId
                             * @param element
                             * @returns HttpPromise
                             */
                            update: function (projectId, respondId, taxonomyId, elementId, element) {
                                return $http.put(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/" + taxonomyId + "/carousel/element/" + elementId, element);
                            },

                            /**
                             * Delete element.
                             *
                             * @param projectId
                             * @param respondId
                             * @param taxonomyId
                             * @param elementId
                             * @returns HttpPromise
                             */
                            delete: function (projectId, respondId, taxonomyId, elementId) {
                                return $http.delete(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/" + taxonomyId + "/carousel/element/" + elementId);
                            },

                            /**
                             * Button helpers.
                             */
                            button: {
                                /**
                                 * Url button helpers.
                                 */
                                url: {
                                    /**
                                     * Store.
                                     *
                                     * @param projectId
                                     * @param respondId
                                     * @param taxonomyId
                                     * @param elementId
                                     * @param button
                                     * @returns HttpPromise
                                     */
                                    store: function (projectId, respondId, taxonomyId, elementId, button) {
                                        return $http.post(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/" + taxonomyId + "/carousel/element/" + elementId + "/button/url", button);
                                    },

                                    /**
                                     * Update.
                                     *
                                     * @param projectId
                                     * @param respondId
                                     * @param taxonomyId
                                     * @param elementId
                                     * @param buttonId
                                     * @param button
                                     * @returns HttpPromise
                                     */
                                    update: function (projectId, respondId, taxonomyId, elementId, buttonId, button) {
                                        return $http.put(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/" + taxonomyId + "/carousel/element/" + elementId + "/button/url/" + buttonId, button);
                                    }
                                },

                                /**
                                 * Phone button helpers.
                                 */
                                phone: {
                                    /**
                                     * Store.
                                     *
                                     * @param projectId
                                     * @param respondId
                                     * @param taxonomyId
                                     * @param elementId
                                     * @param button
                                     * @returns HttpPromise
                                     */
                                    store: function (projectId, respondId, taxonomyId, elementId, button) {
                                        return $http.post(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/" + taxonomyId + "/carousel/element/" + elementId + "/button/phone", button);
                                    },

                                    /**
                                     * Update.
                                     *
                                     * @param projectId
                                     * @param respondId
                                     * @param taxonomyId
                                     * @param elementId
                                     * @param buttonId
                                     * @param button
                                     * @returns HttpPromise
                                     */
                                    update: function (projectId, respondId, taxonomyId, elementId, buttonId, button) {
                                        return $http.put(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/" + taxonomyId + "/carousel/element/" + elementId + "/button/phone/" + buttonId, button);
                                    }
                                },

                                /**
                                 * Postback button helpers.
                                 */
                                postback: {
                                    /**
                                     * Store.
                                     *
                                     * @param projectId
                                     * @param respondId
                                     * @param taxonomyId
                                     * @param elementId
                                     * @param button
                                     * @returns HttpPromise
                                     */
                                    store: function (projectId, respondId, taxonomyId, elementId, button) {
                                        return $http.post(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/" + taxonomyId + "/carousel/element/" + elementId + "/button/postback", button);
                                    },

                                    /**
                                     * Update.
                                     *
                                     * @param projectId
                                     * @param respondId
                                     * @param taxonomyId
                                     * @param elementId
                                     * @param buttonId
                                     * @param button
                                     * @returns HttpPromise
                                     */
                                    update: function (projectId, respondId, taxonomyId, elementId, buttonId, button) {
                                        return $http.put(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/" + taxonomyId + "/carousel/element/" + elementId + "/button/postback/" + buttonId, button);
                                    }
                                },

                                /**
                                 * Delete button.
                                 *
                                 * @param projectId
                                 * @param respondId
                                 * @param taxonomyId
                                 * @param elementId
                                 * @param buttonId
                                 * @returns HttpPromise
                                 */
                                delete: function (projectId, respondId, taxonomyId, elementId, buttonId) {
                                    return $http.delete(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/" + taxonomyId + "/carousel/element/" + elementId + "/button/" + buttonId);
                                }
                            }
                        },

                        /**
                         * Store.
                         *
                         * @param projectId
                         * @param respondId
                         * @returns HttpPromise
                         */
                        store: function (projectId, respondId) {
                            return $http.post(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/carousel", {});
                        }
                    },

                    /**
                     * Buttons helper.
                     */
                    button: {
                        /**
                         * Button helper.
                         */
                        button: {
                            /**
                             * Url button helpers.
                             */
                            url: {
                                /**
                                 * Store.
                                 *
                                 * @param projectId
                                 * @param respondId
                                 * @param taxonomyId
                                 * @param button
                                 * @returns HttpPromise
                                 */
                                store: function (projectId, respondId, taxonomyId, button) {
                                    return $http.post(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/" + taxonomyId + "/button/url", button);
                                },

                                /**
                                 * Update.
                                 *
                                 * @param projectId
                                 * @param respondId
                                 * @param taxonomyId
                                 * @param buttonId
                                 * @param button
                                 * @returns HttpPromise
                                 */
                                update: function (projectId, respondId, taxonomyId, buttonId, button) {
                                    return $http.put(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/" + taxonomyId + "/button/url/" + buttonId, button);
                                }
                            },

                            /**
                             * Phone button helpers.
                             */
                            phone: {
                                /**
                                 * Store.
                                 *
                                 * @param projectId
                                 * @param respondId
                                 * @param taxonomyId
                                 * @param button
                                 * @returns HttpPromise
                                 */
                                store: function (projectId, respondId, taxonomyId, button) {
                                    return $http.post(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/" + taxonomyId + "/button/phone", button);
                                },

                                /**
                                 * Update.
                                 *
                                 * @param projectId
                                 * @param respondId
                                 * @param taxonomyId
                                 * @param buttonId
                                 * @param button
                                 * @returns HttpPromise
                                 */
                                update: function (projectId, respondId, taxonomyId, buttonId, button) {
                                    return $http.put(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/" + taxonomyId + "/button/phone/" + buttonId, button);
                                }
                            },

                            /**
                             * Postback button helpers.
                             */
                            postback: {
                                /**
                                 * Store.
                                 *
                                 * @param projectId
                                 * @param respondId
                                 * @param taxonomyId
                                 * @param button
                                 * @returns HttpPromise
                                 */
                                store: function (projectId, respondId, taxonomyId, button) {
                                    return $http.post(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/" + taxonomyId + "/button/postback", button);
                                },

                                /**
                                 * Update.
                                 *
                                 * @param projectId
                                 * @param respondId
                                 * @param taxonomyId
                                 * @param buttonId
                                 * @param button
                                 * @returns HttpPromise
                                 */
                                update: function (projectId, respondId, taxonomyId, buttonId, button) {
                                    return $http.put(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/" + taxonomyId + "/button/postback/" + buttonId, button);
                                }
                            },

                            /**
                             * Delete button.
                             *
                             * @param projectId
                             * @param respondId
                             * @param taxonomyId
                             * @param buttonId
                             * @returns HttpPromise
                             */
                            delete: function (projectId, respondId, taxonomyId, buttonId) {
                                return $http.delete(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/" + taxonomyId + "/button/" + buttonId);
                            }
                        },

                        /**
                         * Store new button.
                         *
                         * @param projectId
                         * @param respondId
                         * @param button
                         * @returns HttpPromise
                         */
                        store: function (projectId, respondId, button) {
                            return $http.post(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/button", button);
                        },

                        /**
                         * Update existing button.
                         *
                         * @param projectId
                         * @param respondId
                         * @param taxonomyId
                         * @param button
                         * @returns HttpPromise
                         */
                        update: function (projectId, respondId, taxonomyId, button) {
                            return $http.put(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/" + taxonomyId + "/button", button);
                        }
                    },

                    /**
                     * Btn helper.
                     */
                    btn: {
                        /**
                         * Phone helper.
                         */
                        phone: {
                            /**
                             * Store.
                             *
                             * @param projectId
                             * @param respondId
                             * @param button
                             * @returns HttpPromise
                             */
                            store: function (projectId, respondId, button) {
                                return $http.post(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/btn/phone", button);
                            },

                            /**
                             * Update.
                             *
                             * @param projectId
                             * @param respondId
                             * @param taxonomyId
                             * @param button
                             * @returns HttpPromise
                             */
                            update: function (projectId, respondId, taxonomyId, button) {
                                return $http.put(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/" + taxonomyId + "/btn/phone", button);
                            }
                        },

                        /**
                         * Postback helper.
                         */
                        postback: {
                            /**
                             * Store.
                             *
                             * @param projectId
                             * @param respondId
                             * @param button
                             * @returns HttpPromise
                             */
                            store: function (projectId, respondId, button) {
                                return $http.post(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/btn/postback", button);
                            },

                            /**
                             * Update.
                             *
                             * @param projectId
                             * @param respondId
                             * @param taxonomyId
                             * @param button
                             * @returns HttpPromise
                             */
                            update: function (projectId, respondId, taxonomyId, button) {
                                return $http.put(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/" + taxonomyId + "/btn/postback", button);
                            }
                        },

                        /**
                         * Url helper.
                         */
                        url: {
                            /**
                             * Store.
                             *
                             * @param projectId
                             * @param respondId
                             * @param button
                             * @returns HttpPromise
                             */
                            store: function (projectId, respondId, button) {
                                return $http.post(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/btn/url", button);
                            },

                            /**
                             * Update.
                             *
                             * @param projectId
                             * @param respondId
                             * @param taxonomyId
                             * @param button
                             * @returns HttpPromise
                             */
                            update: function (projectId, respondId, taxonomyId, button) {
                                return $http.put(BASE_URL + "/api/project/" + projectId + "/respond/" + respondId + "/taxonomy/" + taxonomyId + "/btn/url", button);
                            }
                        }
                    }
                }
            }
        }
    });