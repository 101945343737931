angular
    .module("messengerBotApp")
    .directive("respondTaxonomyRenderButtonsButton", function (ApiUtils, toastr, SweetAlert, $uibModal) {
        var icons = {
            "respond.type.button.url": {
                icon: "fa-link",
                description: "Send URL",
                type: "Url"
            },
            "respond.type.button.postback": {
                icon: "fa-reply",
                description: "Send actions",
                type: "Postback"
            },
            "respond.type.button.phone": {
                icon: "fa-phone",
                description: "Send phone number",
                type: "Phone"
            }
        };

        return {
            /**
             * Only match element name.
             */
            restrict: "E",

            scope: {
                taxonomy: "=taxonomy",
                button: "=button"
            },

            /**
             * @param scope
             */
            link: function (scope) {
                /**
                 * Button copy.
                 */
                var buttonCopy = angular.copy(scope.button);

                /**
                 * Saving state.
                 *
                 * @type {boolean}
                 */
                scope.saving = false;

                /**
                 * Return icon.
                 *
                 * @param button
                 * @returns {*}
                 */
                scope.getIcon = function (button) {
                    return icons[button.type_type].icon;
                };

                /**
                 * Return description.
                 *
                 * @param button
                 * @returns {*}
                 */
                scope.getDescription = function (button) {
                    return icons[button.type_type].description;
                };

                /**
                 * Delete button.
                 *
                 * @param button
                 */
                scope.deleteButton = function (button) {
                    SweetAlert.swal({
                        title: "Are you sure?",
                        text: "You will not be able to recover this button!",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Yes, delete it!",
                        closeOnConfirm: false
                    }, function (confirm){
                        if (confirm) {
                            ApiUtils
                                .respond
                                .taxonomy
                                .button
                                .button
                                .delete(PROJECT_ID, RESPOND_ID, scope.taxonomy.id, button.id)
                                .then(function (response) {
                                    if (response.data.success) {
                                        toastr.success("Button deleted successfully.");
                                        scope.taxonomy.type.buttons = scope.taxonomy.type.buttons.filter(function (item) {
                                            return item.id !== button.id;
                                        });
                                    } else {
                                        toastr.error('Error occurred when deleting button.');
                                    }
                                }, function () {
                                    toastr.error('Error occurred when deleting button.');
                                });
                        }

                        SweetAlert.close();
                    });
                };

                /**
                 * Edit button.
                 *
                 * @param taxonomy
                 * @param button
                 */
                scope.editButton = function (taxonomy, button) {
                    var modalInstance = $uibModal.open({
                        templateUrl: "RespondTaxonomyButton" + icons[button.type_type].type + "ModalController.html",
                        controller: "RespondTaxonomyButton" + icons[button.type_type].type + "ModalController",
                        resolve: {
                            taxonomy: function () {
                                return taxonomy;
                            },
                            button: function () {
                                return button;
                            }
                        }
                    });

                    modalInstance.result.then(function (updatedButton) {
                        angular.forEach(taxonomy.type.buttons, function (b, k) {
                            if (b.id == updatedButton.id) {
                                taxonomy.type.buttons[k] = updatedButton;
                            }
                        });
                        toastr.success("Button updated successfully.");
                    });
                };
            },

            /**
             * Return template.
             *
             * @returns {string}
             */
            template: function () {
                return "<div class=\"respond-taxonomy-render-buttons-button input-group input-group-sm\"><span class=\"input-group-btn\"><button class=\"btn btn-danger\" tooltip-trigger=\"mouseenter\" uib-tooltip=\"Delete button\" type=\"button\" ng-click=\"deleteButton(button)\"><i class=\"fa fa-trash\"></i></button></span><input type=\"text\" class=\"form-control\" placeholder=\"Button caption\" ng-model=\"button.type.title\" ng-disabled=\"true\"><span class=\"input-group-addon\"><i uib-tooltip=\"{{ getDescription(button) }}\" tooltip-trigger=\"mouseenter\" class=\"fa {{ getIcon(button) }}\"></i></span><span class=\"input-group-btn\"><button class=\"btn btn-default\" type=\"button\" uib-tooltip=\"Edit button\" tooltip-trigger=\"mouseenter\" ng-click=\"editButton(taxonomy, button)\"><i class=\"fa fa-pencil\"></i></button></span></div>";
            }
        }
    });
