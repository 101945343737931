angular
    .module("messengerBotApp")
    .controller("RespondTaxonomyFormSubscribeModalController", function ($scope, ApiUtils, $uibModalInstance, $http, taxonomy) {
        /**
         * Set initial validation state.
         *
         * @type {boolean}
         */
        $scope.saving = false;

        /**
         * Initial errors.
         *
         * @type {{}}
         */
        $scope.errors = {};

        if (taxonomy.id) {
            $scope.taxonomy = angular.copy(taxonomy);
        } else {
            $scope.taxonomy = { type: {} };
        }

        /**
         * Set initial channels.
         *
         * @type {{}}
         */
        $scope.channels = [];

        /**
         * Set initial loading state.
         *
         * @type {boolean}
         */
        $scope.loading = true;

        /**
         * Load all channels.
         */
        $http.get(BASE_URL + "/api/project/" + PROJECT_ID + "/subscription/channel", { params: { all: true } }).then(function (response) {
            $scope.channels = response.data;
        }).finally(function () {
            $scope.loading = false;
        });

        /**
         * Save matcher.
         */
        $scope.save = function () {
            $scope.saving = true;

            var clone = angular.copy($scope.taxonomy);

            if (clone.type.subscription_channel) {
                clone.type.subscription_channel = clone.type.subscription_channel.id;
            }

            if (!$scope.taxonomy.id) {
                ApiUtils
                    .respond
                    .taxonomy
                    .subscribe
                    .store(PROJECT_ID, RESPOND_ID, clone.type)
                    .then(function (response) {
                        $uibModalInstance.close(response.data);
                    }, function (response) {
                        $scope.errors = response.data;
                    })
                    .finally(function () {
                        $scope.saving = false;
                    });
            } else {
                ApiUtils
                    .respond
                    .taxonomy
                    .subscribe
                    .update(PROJECT_ID, RESPOND_ID, taxonomy.id, clone.type)
                    .then(function (response) {
                        $uibModalInstance.close(response.data);
                    }, function (response) {
                        $scope.errors = response.data;
                    })
                    .finally(function () {
                        $scope.saving = false;
                    });
            }
        };

        /**
         * Dismiss modal.
         */
        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        /**
         * If option has changed reset errors.
         */
        $scope.$watch("taxonomy.type.subscription_channel", function () {
            $scope.errors.subscription_channel = [];
        });

        /**
         * If option has changed reset errors.
         */
        $scope.$watch("taxonomy.type.option", function () {
            $scope.errors.option = [];
        });
    });