angular
    .module("messengerBotApp")
    .controller("RespondTaxonomyCarouselController", function ($scope, $uibModal, toastr, ApiUtils) {
        /**
         * Add new element.
         */
        $scope.addElement = function () {
            var modalInstance = $uibModal.open({
                templateUrl: "RespondTaxonomyCarouselElementModalController.html",
                controller: "RespondTaxonomyCarouselElementModalController",
                resolve: {
                    taxonomy: function () {
                        return $scope.taxonomy;
                    },
                    element: function () {
                        return {}
                    }
                }
            });

            modalInstance.result.then(function (element) {
                $scope.taxonomy.type.elements.push(element);
                toastr.success("Element added successfully.");
            });
        };

        /**
         * Set sortable options.
         *
         * @type {{}}
         */
        $scope.elementsSortableOptions = {
            "ui-floating": true,
            "handle": ".drag-handle-element",
            placeholder: "element-sortable-placeholder pull-left",
            "stop": function (e, ui) {
                var positions = [];

                angular.forEach($scope.taxonomy.type.elements, function (element, i) {
                    positions.push({
                        element: element.id,
                        position: i + 1
                    })
                });

                ApiUtils
                    .respond
                    .taxonomy
                    .carousel
                    .element
                    .sort(PROJECT_ID, RESPOND_ID, $scope.taxonomy.id, positions)
                    .then(function (response) {
                        if (response.data.success) {
                            toastr.success("Elements order changed.");
                        } else {
                            toastr.error('Failed to update elements order.');
                        }
                    }, function (response) {
                        toastr.error('Failed to update elements order.');
                    });
            }
        };
    });