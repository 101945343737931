angular
    .module("messengerBotApp")
    .directive("respondTaxonomyTypeIcon", function () {
        var icons = {
            "respond.type.audio": {
                icon: "fa-file-audio-o",
                description: "Send audio"
            },
            "respond.type.button": {
                icon: "fa-th-list",
                description: "Send buttons"
            },
            "respond.type.button.url": {
                icon: "fa-link",
                description: "Button with link"
            },
            "respond.type.button.phone": {
                icon: "fa-phone",
                description: "Button with phone number"
            },
            "respond.type.button.postback": {
                icon: "fa-reply",
                description: "Button with actions"
            },
            "respond.type.callback": {
                icon: "fa-refresh",
                description: "Send response from an API callback"
            },
            "respond.type.carousel": {
                icon: "fa-th-large",
                description: "Send elements carousel as a response"
            },
            "respond.type.chat_toggle": {
                icon: "fa-toggle-on",
                description: "Enable / disable bot chat for recipient"
            },
            "respond.type.file": {
                icon: "fa-file-o",
                description: "Send file"
            },
            "respond.type.image": {
                icon: "fa-picture-o",
                description: "Send image"
            },
            "respond.type.ping": {
                icon: "fa-bullhorn",
                description: "Call (ping) given URL"
            },
            "respond.type.quick_reply": {
                icon: "fa-reply",
                description: "Send quick reply buttons"
            },
            "respond.type.rss": {
                icon: "fa-rss",
                description: "Display RSS feed in carousel"
            },
            "respond.type.save_input": {
                icon: "fa-save",
                description: "Save input into recipient variable"
            },
            "respond.type.subscribe": {
                icon: "fa-user-plus",
                description: "Add / remove user to subscription channel"
            },
            "respond.type.text": {
                icon: "fa-font",
                description: "Send text message"
            },
            "respond.type.video": {
                icon: "fa-file-video-o",
                description: "Send video"
            }
        };

        return {
            /**
             * Only match element name.
             */
            restrict: "E",

            scope: {
                taxonomy: "=taxonomy"
            },

            /**
             * @param scope
             */
            link: function (scope) {
                /**
                 * Return icon.
                 *
                 * @param taxonomy
                 * @returns {*}
                 */
                scope.getIcon = function (taxonomy) {
                    return icons[taxonomy.type_type].icon;
                };

                /**
                 * Return description.
                 *
                 * @param taxonomy
                 * @returns {*}
                 */
                scope.getDescription = function (taxonomy) {
                    return icons[taxonomy.type_type].description;
                };
            },

            /**
             * Return template.
             *
             * @returns {string}
             */
            template: function () {
                return "<span class=\"respond-taxonomy-block-icon\" uib-tooltip=\"{{ getDescription(taxonomy) }}\" tooltip-trigger=\"mouseenter\"><i class=\"fa {{ getIcon(taxonomy) }}\"></i></span>"
            }
        }
    });
