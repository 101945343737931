angular
    .module("messengerBotApp")
    .controller("ProjectSettingsDeleteController", function ($scope, ApiUtils, toastr, $window) {
        /**
         * Set initial deleting state.
         *
         * @type {boolean}
         */
        $scope.deleting = false;

        /**
         * Initial errors.
         *
         * @type {{}}
         */
        $scope.errors = {};

        /**
         * Process delete.
         */
        $scope.delete = function () {
            $scope.deleting = true;

            ApiUtils
                .project
                .delete(PROJECT_ID, $scope.title)
                .then(function () {
                    toastr.success("Project deleted successfully.");
                    $window.location.href = BASE_URL + "/projects";
                }, function (response) {
                    $scope.errors = response.data;
                    toastr.error("Failed to delete project.");
                })
                .finally(function () {
                    $scope.deleting = false;
                });
        };

        /**
         * If option has changed reset errors.
         */
        $scope.$watch("title", function () {
            $scope.errors.title = [];
        });
    });