angular
    .module("messengerBotApp")
    .controller("ProjectSettingsController", function ($scope, $http) {
        /**
         * Set initial loading state.
         *
         * @type {boolean}
         */
        $scope.projectLoading = true;

        /**
         * Initial project.
         *
         * @type {{}}
         */
        $scope.project = {};

        /**
         * Load project.
         */
        $http.get(BASE_URL + "/api/project/" + PROJECT_ID).then(function (response) {
            $scope.project = response.data;

            $scope.$broadcast("project.loaded", $scope.project);
        }).finally(function () {
            $scope.projectLoading = false;
        });
    });