angular
    .module("messengerBotApp")
    .controller("RespondTaxonomiesController", function ($scope, $http, ApiUtils, toastr, SweetAlert, $uibModal) {
        /**
         * Map type to controller.
         *
         * @type {*}
         */
        var controllerByType = {
            "respond.type.audio": "RespondTaxonomyFormAudioModalController",
            "respond.type.button": "RespondTaxonomyFormButtonModalController",
            "respond.type.button.phone": "RespondTaxonomyFormBtnPhoneModalController",
            "respond.type.button.postback": "RespondTaxonomyFormBtnPostbackModalController",
            "respond.type.button.url": "RespondTaxonomyFormBtnUrlModalController",
            "respond.type.callback": "RespondTaxonomyFormCallbackModalController",
            "respond.type.carousel": "RespondTaxonomyFormCarouselModalController",
            "respond.type.chat_toggle": "RespondTaxonomyFormChatToggleModalController",
            "respond.type.file": "RespondTaxonomyFormFileModalController",
            "respond.type.image": "RespondTaxonomyFormImageModalController",
            "respond.type.ping": "RespondTaxonomyFormPingModalController",
            "respond.type.quick_reply": "RespondTaxonomyFormQuickReplyModalController",
            "respond.type.rss": "RespondTaxonomyFormRssModalController",
            "respond.type.save_input": "RespondTaxonomyFormSaveInputModalController",
            "respond.type.subscribe": "RespondTaxonomyFormSubscribeModalController",
            "respond.type.text": "RespondTaxonomyFormTextModalController",
            "respond.type.video": "RespondTaxonomyFormVideoModalController"
        };

        /**
         * Set initial loading state.
         *
         * @type {boolean}
         */
        $scope.taxonomiesLoading = true;

        /**
         * @type {Array}
         */
        $scope.taxonomies = [];

        /**
         * Load project.
         */
        $http.get(BASE_URL + "/api/project/" + PROJECT_ID + "/respond/" + RESPOND_ID + "/taxonomy").then(function (response) {
            $scope.taxonomies = response.data;
        }).finally(function () {
            $scope.taxonomiesLoading = false;
        });

        /**
         * Set sortable options.
         *
         * @type {{}}
         */
        $scope.sortableOptions = {
            "ui-floating": true,
            "handle": ".drag-handle",
            placeholder: "respond-configs-sortable-placeholder",
            "stop": function (e, ui) {
                var positions = [];

                angular.forEach($scope.taxonomies, function (taxonomy, i) {
                    positions.push({
                        taxonomy: taxonomy.id,
                        position: i + 1
                    })
                });

                ApiUtils
                    .respond
                    .taxonomy
                    .sort(PROJECT_ID, RESPOND_ID, positions)
                    .then(function (response) {
                        if (response.data.success) {
                            toastr.success("Taxonomies order changed.");
                        } else {
                            toastr.error('Failed to update taxonomies order.');
                        }
                    }, function (response) {
                        toastr.error('Failed to update taxonomies order.');
                    });
            }
        };

        /**
         * Delete taxonomy.
         *
         * @param taxonomy
         */
        $scope.deleteTaxonomy = function (taxonomy) {
            SweetAlert.swal({
                title: "Are you sure?",
                text: "You will not be able to recover this taxonomy!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes, delete it!",
                closeOnConfirm: false
            }, function (confirm){
                if (confirm) {
                    ApiUtils
                        .respond
                        .taxonomy
                        .delete(PROJECT_ID, RESPOND_ID, taxonomy.id)
                        .then(function (response) {
                            if (response.data.success) {
                                toastr.success("Taxonomy deleted successfully.");
                                $scope.taxonomies = $scope.taxonomies.filter(function (item) {
                                    return item.id !== taxonomy.id;
                                });
                            } else {
                                toastr.error('Error occurred when deleting flow.');
                            }
                        }, function () {
                            toastr.error('Error occurred when deleting taxonomy.');
                        });
                }

                SweetAlert.close();
            });
        };

        /**
         * Determine if type support edit.
         *
         * @param taxonomy
         * @returns {boolean}
         */
        $scope.supportsEdit = function (taxonomy) {
            if (taxonomy.type_type == 'respond.type.carousel') {
                return false;
            }

            return true;
        };

        /**
         * Add new taxonomy.
         *
         * @param type
         */
        $scope.addTaxonomy = function (type) {
            if (type == "Carousel") {
                ApiUtils
                    .respond
                    .taxonomy
                    .carousel
                    .store(PROJECT_ID, RESPOND_ID)
                    .then(function (response) {
                        $scope.taxonomies.push(response.data);
                        toastr.success("Taxonomy added successfully.");
                    });

                return;
            }

            var modalInstance = $uibModal.open({
                templateUrl: "RespondTaxonomyForm" + type + "ModalController.html",
                controller: "RespondTaxonomyForm" + type + "ModalController",
                resolve: {
                    taxonomy: function () {
                        return {};
                    }
                }
            });

            modalInstance.result.then(function (taxonomy) {
                $scope.taxonomies.push(taxonomy);
                toastr.success("Taxonomy added successfully.");
            });
        };

        /**
         * Add new taxonomy.
         *
         * @param taxonomy
         */
        $scope.editTaxonomy = function (taxonomy) {
            var modalInstance = $uibModal.open({
                templateUrl: controllerByType[taxonomy.type_type] + ".html",
                controller: controllerByType[taxonomy.type_type],
                resolve: {
                    taxonomy: function () {
                        return taxonomy;
                    }
                }
            });

            modalInstance.result.then(function (updatedTaxonomy) {
                angular.forEach($scope.taxonomies, function (t, k) {
                    if (t.id == updatedTaxonomy.id) {
                        $scope.taxonomies[k] = updatedTaxonomy;
                    }
                });
                toastr.success("Taxonomy updated successfully.");
            });
        };
    });