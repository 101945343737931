angular
    .module("messengerBotApp")
    .controller("RespondsEditController", function ($scope, $http, ApiUtils, toastr) {
        /**
         * Set initial loading state.
         *
         * @type {boolean}
         */
        $scope.respondLoading = true;

        /**
         * Initial respond.
         *
         * @type {{}}
         */
        $scope.respond = {};

        var respond = {};

        /**
         * Initial saving state.
         *
         * @type {boolean}
         */
        $scope.saving = false;

        /**
         * Load project.
         */
        $http.get(BASE_URL + "/api/project/" + PROJECT_ID + "/respond/" + RESPOND_ID).then(function (response) {
            $scope.respond = response.data;
            respond = angular.copy($scope.respond);

            $scope.$broadcast("respond.loaded", $scope.respond);
        }).finally(function () {
            $scope.respondLoading = false;
        });

        /**
         * Save respond.
         */
        $scope.saveRespond = function () {
            if ($scope.saving) {
                return;
            }

            if (angular.equals(respond, $scope.respond)) {
                return;
            }

            $scope.saving = true;

            ApiUtils
                .respond
                .update(PROJECT_ID, RESPOND_ID, $scope.respond)
                .then(function (response) {
                    $scope.respond = response.data;
                    respond = angular.copy($scope.respond);
                    toastr.success("Respond updated.");
                }, function (response) {
                    toastr.error("Failed to update respond details.");
                })
                .finally(function () {
                    $scope.saving = false;
                });
        };
    });